import { render, staticRenderFns } from "./watchlist.vue?vue&type=template&id=67f9013c"
import script from "./watchlist.vue?vue&type=script&lang=js"
export * from "./watchlist.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/.pnpm/vue-loader@15.11.1_babel-core@6.26.3_cache-loader@4.1.0_css-loader@5.2.7_lodash@4.17.21_prett_mslvjkx5fqxq5hwkswdqi36zjm/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WatchlistCard: require('/vercel/path0/components/watchlist/WatchlistCard.vue').default,WatchlistEvents: require('/vercel/path0/components/watchlist/WatchlistEvents.vue').default,WatchlistNews: require('/vercel/path0/components/watchlist/WatchlistNews.vue').default,WatchlistSigDevs: require('/vercel/path0/components/watchlist/WatchlistSigDevs.vue').default,WatchlistFilings: require('/vercel/path0/components/watchlist/WatchlistFilings.vue').default,WatchlistTranscripts: require('/vercel/path0/components/watchlist/WatchlistTranscripts.vue').default})
