import "core-js/modules/es.array.find-index.js";
import "core-js/modules/es.object.keys.js";
import WatchlistCard from "~/components/watchlist/WatchlistCard.vue";
import WatchlistNews from "~/components/watchlist/WatchlistNews.vue";
import WatchlistFilings from "~/components/watchlist/WatchlistFilings.vue";
import WatchlistSigDevs from "~/components/watchlist/WatchlistSigDevs.vue";
import WatchlistTranscripts from "~/components/watchlist/WatchlistTranscripts.vue";
import useBaseUtils from "~/functions/useBaseUtils";
export default defineComponent({
  components: {
    WatchlistCard: WatchlistCard,
    WatchlistNews: WatchlistNews,
    WatchlistFilings: WatchlistFilings,
    WatchlistSigDevs: WatchlistSigDevs,
    WatchlistTranscripts: WatchlistTranscripts
  },
  setup: function setup() {
    var _useBaseUtils = useBaseUtils(),
      dev = _useBaseUtils.dev,
      refCode = _useBaseUtils.refCode,
      customGroup = _useBaseUtils.customGroup,
      numWatchlists = _useBaseUtils.numWatchlists,
      I18nFn = _useBaseUtils.I18nFn;
    return {
      dev: dev,
      refCode: refCode,
      customGroup: customGroup,
      numWatchlists: numWatchlists,
      I18nFn: I18nFn
    };
  },
  data: function data() {
    return {
      currentTab: "#watchlist",
      newList: false,
      tabbed: true
    };
  },
  computed: {
    wid: function wid() {
      return this.$route.query.wid;
    },
    initialWatchlistFetched: function initialWatchlistFetched() {
      return this.$store.state.watchlist.initialWatchlistFetched;
    },
    fetchingWatchlists: function fetchingWatchlists() {
      return this.$store.state.watchlist.fetchingWatchlists;
    },
    fetchingRkdIds: function fetchingRkdIds() {
      return this.$store.state.watchlist.fetchingRkdIds;
    },
    watchlistMap: function watchlistMap() {
      return this.$store.state.watchlist.watchlists || {};
    },
    displayedWatchlist: function displayedWatchlist() {
      return this.watchlistMap[this.wid] || {
        tickers: []
      };
    },
    watchlistIdx: function watchlistIdx() {
      var _this = this;
      return this.watchlistIds.findIndex(function (wid) {
        return wid === _this.wid;
      });
    },
    displayedTickers: function displayedTickers() {
      return this.displayedWatchlist.tickers;
    },
    watchlistIds: function watchlistIds() {
      return Object.keys(this.watchlistMap);
    }
  },
  created: function created() {
    // if (!this.initialWatchlistFetched) {
    if (this.watchlistIds.length <= 1) {
      // trying to see if I can force fetch watchlists if only
      // one watchlist is shown... eliminating reported error
      // I'm worried than many users only have one watchlist resulting in
      // many fetches to this endpoint
      // guess I can work it out later?
      this.$store.dispatch("watchlist/getWatchlists");
    }
  },
  methods: {
    closeAddWatchlist: function closeAddWatchlist() {
      // pass the id with $emit(newId)
      // should this be coming from the component or from the store?
      // I suppose it makes sense to do it all in the component, less connections
      // console.log("close add watchlist called with: ", id)
      this.newList = !this.newList;
      this.$store.commit("watchlist/setWatchlistError", {
        error: "addWatchlistError",
        status: null
      }); // if (id) {
      //   this.activeTab = id
      // }
    }
  }
});